.termsOfService {
    color: #666;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 16pt;
    font-weight: 300;
    line-height: 1.65em;
}

.termsOfService section {
    margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

.termsOfService h1,
.termsOfService h2,
.termsOfService h3,
.termsOfService h4,
.termsOfService h5,
.termsOfService h6 {
    color: #555;
    line-height: 1em;
    margin: 0 0 1em 0;
}

.termsOfService h1 {
    font-size: 2.25em;
    line-height: 1.35em;
}

.termsOfService h1 a,
.termsOfService h2 a,
.termsOfService h3 a,
.termsOfService h4 a,
.termsOfService h5 a,
.termsOfService h6 a {
    color: inherit;
    text-decoration: none;
}

.termsOfService p {
    margin: 0 0 2em 0;
}

.termsOfService header p {
    color: #999;
    margin: 0 0 1.5em 0;
    position: relative;
}

.termsOfService header h2 + p {
    font-size: 1.25em;
    line-height: 1.5em;
    margin-top: -1em;
}

.termsOfService .tos-section {
    font-weight: 500;
    padding-inline: 2rem;
}

.termsOfService .tos-section-title {
font-size: xx-large;
margin-bottom: .75rem;
}

.termsOfService ol {
list-style-type: decimal;
}
